




































































































































































































































































































































import { Component, Prop, Emit, Vue } from 'vue-property-decorator'
import dayjs from 'dayjs'

import Project from '@/models/project'
import Task from '@/models/task'
import User from '@/models/user'
import TaskStatus from '@/models/task_status'
import TaskAssignment from '@/models/task_assignment'

import InputText from 'primevue/inputtext'
import MultiSelect from 'primevue/multiselect'
import SplitButton from 'primevue/splitbutton'
import Button from 'primevue/button'
import Calendar from 'primevue/calendar'
import Dropdown from 'primevue/dropdown'
import Menu from 'primevue/menu'
import AppColorTag from '@/components/AppColorTag'
import AppColorBadge from '@/components/AppColorBadge'
import ProjectSelector from './ProjectSelector.vue'
import AssigneeSelector from './AssigneeSelector.vue'
import AppDialog from '@/components/AppDialog'

Vue.component('MultiSelect', MultiSelect)
Vue.component('InputText', InputText)
Vue.component('SplitButton', SplitButton)
Vue.component('Button', Button)
Vue.component('Calendar', Calendar)
Vue.component('Dropdown', Dropdown)
Vue.component('Menu', Menu)
Vue.component('AppColorTag', AppColorTag)
Vue.component('AppColorBadge', AppColorBadge)
Vue.component('AppDialog', AppDialog)
Vue.component('ProjectSelector', ProjectSelector)
Vue.component('AssigneeSelector', AssigneeSelector)

@Component
export default class TaskRow extends Vue {
  @Prop() task!: Task
  @Prop() assigneeOptions!: User[]
  @Prop() taskStatuses!: TaskStatus[]
  @Prop() projects!: Project[]
  @Prop() memberUserLookup!: { [key: string]: User }
  @Prop() userMemberIdLookup!: { [key: string]: string }
  @Prop() isExpanded!: boolean
  @Prop() isSelected!: boolean
  @Prop() showDueDate!: boolean
  @Prop() filteredAssignee!: User | null
  @Prop() maxDescriptionCharacters!: number

  processing = false

  selectedAssignees: User[] = []

  showDescriptionEditor = false
  showProjectSelector = false
  showProjectSelectorDropdown = false
  showAssigneeSelector = false
  showDueDateSelector = false
  showTagDateSelector = false
  showTaskDeleteDialog = false

  wasNew = false

  actionItems = [
    {
      label: 'Delete Task',
      icon: 'pi pi-times',
      command: () => {
        this.promptForDelete()
      }
    }
  ]

  get isNewTask () {
    return !this.task.id
  }

  get isInTable () {
    return this.task.ancestryDepth > 0 || this.task.id
  }

  get taskStatusesAsItems () {
    return this.taskStatuses.map(item => {
      return {
        label: item.name,
        command: () => {
          this.setSelectedTaskStatus(item)
        }
      }
    })
  }

  get disableSave () {
    return this.processing ||
      !this.task.description
  }

  get hasSubtasks () {
    return this.task.childrenCount > 0
  }

  mounted () {
    this.setUpActionItems()
    if (this.isNewTask && this.filteredAssignee) {
      this.setSelectedAssignees([this.filteredAssignee])
    }
  }

  setUpActionItems () {
    const isRootTask = this.task.ancestryDepth < 1
    if (isRootTask) {
      this.actionItems.unshift({
        label: 'Add Subtask',
        icon: 'pi pi-plus',
        command: () => {
          this.addSubtask()
        }
      })
    } else {
      this.actionItems.unshift({
        label: 'Make Root Task',
        icon: 'pi pi-angle-double-up',
        command: () => {
          this.promoteTask()
        }
      })
      this.actionItems.unshift({
        label: 'Add Due Date',
        icon: 'pi pi-plus',
        command: () => {
          this.toggleTagDateSelector(true)
        }
      })
    }
    this.actionItems.unshift({
      label: 'Add Note',
      icon: 'pi pi-plus',
      command: () => {
        this.openDetailDialog()
      }
    })
  }

  isBeforeToday (date: Date) {
    return dayjs(date).diff(dayjs(), 'days') < 0
  }

  dayOfWeek (date: Date) {
    return dayjs(date).format('dddd')
  }

  isOverdue (task: Task) {
    const finalStatuses = ['Done', 'Canceled']
    return !finalStatuses.includes(task.taskStatus.name) &&
      this.isBeforeToday(task.dueDate as Date)
  }

  async setSelectedTaskStatus (taskStatus: TaskStatus) {
    this.task.taskStatus = taskStatus

    if (!this.isNewTask) {
      await this.task.save({ with: ['taskStatus.id'] })
      this.saveComplete()

      if (taskStatus.name === 'Done') {
        this.showTaskCompletedMessage()
      }
    }
  }

  showTaskCompletedMessage () {
    const detailMessages = [
      'Great Job!',
      'Awesome!',
      'Nice Work!',
      'Congrats!',
      'Keep It Up!',
      'Way To Go!'
    ]
    const summaryMessage = 'Task Completed'
    const detailMessage = detailMessages[Math.floor(Math.random() * 6)]
    this.$toast.add({ severity: 'success', summary: summaryMessage, detail: detailMessage, life: 3000 })
  }

  async setSelectedProject (project: Project) {
    this.task.project = project

    if (!this.isNewTask) {
      if (!project) {
        this.task.projectId = null
        this.task.project = null
      }
      await this.task.save({ with: ['project.id'] })
      this.toggleProjectSelector(false)
      this.saveComplete()
    }
  }

  async setSelectedAssignees (users: User[]) {
    this.selectedAssignees = users

    if (!this.isNewTask) {
      await this.saveTaskAssignments(this.task)
      this.saveComplete()
    }
  }

  toggleActionMenu (event: any) {
    (this.$refs.action_menu as any).toggle(event)
  }

  toggleDescriptionEditor (value: boolean) {
    this.showDescriptionEditor = value

    Vue.nextTick(() => {
      const el = document.getElementById('task_description_input_' + this.task.id)
      if (el) { el.focus() }
    })
  }

  toggleDueDateSelector (value: boolean) {
    this.showDueDateSelector = value

    if (this.task.dueDate) {
      this.task.dueDate = dayjs(this.task.dueDate).format('MM/DD/YYYY')
    }

    Vue.nextTick(() => {
      const el = document.getElementById('due_date_calendar_' + this.task.id)
      if (el) { el.focus() }
    })
  }

  toggleTagDateSelector (value: boolean) {
    this.showTagDateSelector = value

    if (this.task.tagDate) {
      this.task.tagDate = dayjs(this.task.tagDate).format('MM/DD/YYYY')
    }

    Vue.nextTick(() => {
      const el = document.getElementById('tag_date_calendar_' + this.task.id)
      if (el) { el.focus() }
    })
  }

  toggleProjectSelector (value: boolean) {
    this.showProjectSelector = value
    this.toggleProjectSelectorDropdown(value)
  }

  toggleProjectSelectorDropdown (value: boolean) {
    this.showProjectSelectorDropdown = value
    Vue.nextTick(() => {
      const el = document.getElementById('project_selector_' + this.task.id)
      if (!el) return
      setTimeout(() => {
        el.click()
      })
    })
  }

  toggleAssigneeSelector (value: boolean) {
    this.selectedAssignees = this.task.taskAssignments.map((item: TaskAssignment) => {
      return this.memberUserLookup[item.memberId]
    })

    this.showAssigneeSelector = value

    Vue.nextTick(() => {
      const el = document.getElementById('assignee_selector_' + this.task.id)
      if (!el) return
      el.click()
      setTimeout(() => {
        el.click()
      }, 10)
    })
  }

  @Emit()
  toggleCollapse () {
    return this.task
  }

  @Emit()
  beforeDragStart () {
    return this.task
  }

  @Emit()
  selectTask () {
    return this.task
  }

  onProjectSelectorBlur () {
    this.toggleProjectSelectorDropdown(false)
    this.toggleProjectSelector(false)
  }

  onAssigneeSelectorBlur (shouldHideAssigneeSelector: boolean) {
    if (!shouldHideAssigneeSelector) return
    this.toggleAssigneeSelector(false)
  }

  prepareTaskForSave () {
    if (!this.task.dueDate) {
      this.task.dueDate = null
    } else {
      this.task.dueDate = dayjs(this.task.dueDate).format('YYYY-MM-DD')
    }
    if (!this.task.tagDate) {
      this.task.tagDate = null
    } else {
      this.task.tagDate = dayjs(this.task.tagDate).format('YYYY-MM-DD')
    }
  }

  async saveTask (withTaskAssignments = false) {
    if (this.disableSave) return
    this.processing = true
    this.saveStart()
    this.prepareTaskForSave()
    await this.task.save({ with: ['taskStatus.id', 'project.id', 'company.id'] })
    if (withTaskAssignments) {
      await this.saveTaskAssignments(this.task)
    }
    this.saveComplete()
  }

  promptForDelete () {
    this.showTaskDeleteDialog = true
  }

  async archiveTask () {
    this.task.archived = true
    await this.task.save()
    this.saveComplete()
  }

  async promoteTask () {
    const subtask = this.task
    const newRootTask = subtask.dup()

    newRootTask.dueDate = newRootTask.tagDate
    newRootTask.tagDate = null
    newRootTask.ancestry = null
    newRootTask.isPersisted = false

    // set selected assignees for new assignments to be
    //  created after newly promoted task is saved
    this.selectedAssignees = newRootTask.taskAssignments.map(item => {
      return this.memberUserLookup[item.memberId]
    })

    await subtask.destroy()
    this.task = newRootTask
    this.saveTask(true)
  }

  async getTaskAssignments (task: Task) {
    return (await TaskAssignment.where({
      taskId: task.id
    }).per(100000).all()).data
  }

  async saveTaskAssignments (task: Task) {
    // TO DO (FUTURE): make this a single bulk update instead
    //  of several network calls
    const existingTaskAssignments = await this.getTaskAssignments(task)
    const selectedMemberIds = this.selectedAssignees.map(user => {
      return this.userMemberIdLookup[user.id!]
    })
    await this.destroyExistingTaskAssignments(task, existingTaskAssignments, selectedMemberIds)
    await this.addNewTaskAssignments(task, existingTaskAssignments, selectedMemberIds)
  }

  async destroyExistingTaskAssignments (task: Task, existingTaskAssignments: TaskAssignment[], selectedMemberIds: string[]) {
    for (let i = 0; i < existingTaskAssignments.length; i++) {
      const existingTaskAssignment = existingTaskAssignments[i]
      if (!selectedMemberIds.includes(existingTaskAssignment.memberId)) {
        await existingTaskAssignment.destroy()
      }
    }
  }

  async addNewTaskAssignments (task: Task, existingTaskAssignments: TaskAssignment[], selectedMemberIds: string[]) {
    const existingMemberIds = existingTaskAssignments.map(item => { return item.memberId })
    for (let i = 0; i < selectedMemberIds.length; i++) {
      const selectedMemberId = selectedMemberIds[i]
      if (!existingMemberIds.includes(selectedMemberId)) {
        await new TaskAssignment({
          memberId: selectedMemberId,
          taskId: task.id
        }).save()
      }
    }
  }

  cycleTaskStatus (taskStatus: TaskStatus) {
    const taskStatusName = taskStatus ? taskStatus.name : null
    const nextStates: { [key: string]: string } = {
      'To Do': 'In Progress',
      'In Progress': 'Done'
    }
    if (!taskStatusName || !nextStates[taskStatusName]) return
    this.setSelectedTaskStatus(this.taskStatuses.filter(item => {
      return item.name === nextStates[taskStatusName]
    })[0])
  }

  @Emit()
  openDetailDialog () {
    return this.task
  }

  @Emit()
  addSubtask () {
    return this.task
  }

  @Emit()
  saveStart () {
    this.wasNew = this.isNewTask
    return true
  }

  @Emit()
  saveComplete () {
    this.exitEditMode()
    this.processing = false
    return {
      task: this.task,
      wasNew: this.wasNew
    }
  }

  @Emit()
  exitEditMode () {
    this.toggleProjectSelector(false)
    this.toggleProjectSelectorDropdown(false)
    this.toggleDescriptionEditor(false)
    this.toggleAssigneeSelector(false)
    this.toggleDueDateSelector(false)
    this.toggleTagDateSelector(false)
    return true
  }
}
