


































import { Component, Prop, Watch, Emit, Vue } from 'vue-property-decorator'

import Dropdown from 'primevue/dropdown'
import AppColorTag from '@/components/AppColorTag'

Vue.component('Dropdown', Dropdown)
Vue.component('AppColorTag', AppColorTag)

@Component
export default class ProjectSelector extends Vue {
  @Prop() model!: any
  @Prop() options!: any[]
  @Prop() disabled!: boolean
  @Prop() customClasses!: string

  // flag to work around issue with hide being
  //  called when component mounts
  hasHiddenOnceAlready = false

  @Watch('model')
  modelChanged (newValue: any) {
    this.selectionChanged(newValue)
  }

  @Emit()
  selectionChanged (newValue: any) {
    return newValue
  }

  @Emit()
  hide () {
    const result = this.hasHiddenOnceAlready
    this.hasHiddenOnceAlready = true
    return result
  }
}
