

















import { Component, Prop, Emit, Vue } from 'vue-property-decorator'
import { TinyColor } from '@ctrl/tinycolor'

import Color from '@/models/color'

import Button from 'primevue/button'

Vue.component('Button', Button)

@Component
export default class AppColorPicker extends Vue {
  @Prop() colorOptions!: Color[]
  @Prop() value!: string

  @Emit()
  setSelectedColor (color: string) {
    this.value = color
    return this.value
  }

  isDark (color: string) {
    if (!color) return false
    return new TinyColor(color).isDark()
  }
}
