



































































































import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import { Getter, namespace } from 'vuex-class'

import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'

import { TaskGroup } from '@/types/tasks'

import User from '@/models/user'
import Task from '@/models/task'
import TaskStatus from '@/models/task_status'
import Project from '@/models/project'
import Color from '@/models/color'
import Company from '@/models/company'

import TasksTableHeader from './TasksTableHeader.vue'
import TasksTable from './TasksTable.vue'
import TaskRow from './TaskRow.vue'
import Button from 'primevue/button'

Vue.component('TasksTableHeader', TasksTableHeader)
Vue.component('TasksTable', TasksTable)
Vue.component('TaskRow', TaskRow)
Vue.component('Button', Button)

dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)

const Projects = namespace('projects')

@Component
export default class TaskContent extends Vue {
  @Prop() company!: Company
  @Prop() project!: Project
  @Prop() assigneeOptions!: User[]
  @Prop() taskStatuses!: TaskStatus[]
  @Prop() projects!: Project[]
  @Prop() memberUserLookup!: { [key: string]: User }
  @Prop() userMemberIdLookup!: { [key: string]: string }
  @Prop() dateView!: string
  @Prop() defaultAssigneeFilter!: string

  @Projects.Getter taskGroups!: { [key: string]: TaskGroup[] }

  loading = true

  wasExpandedBeforeDragging = false

  tasks: Task[] = []
  rawTasks: Task[] = []
  groupedTasks: TaskGroup[] = []
  selectedTask: Task | null = null

  newSubtask: Task = new Task()
  newTask = new Task({
    project: this.project,
    company: this.company,
    taskStatus: this.taskStatuses[0]
  })

  draggedTasks: any = {}
  collapsedTasks: any = {}

  newSubtaskIsVisible = false
  newTaskIsVisible = false
  ancestrySeparator = '/'

  showCollapseAll = true
  showDueDate = true

  defaultTaskStatuses = [
    'To Do',
    'In Progress'
  ]

  selectedAssignee: User | null = this.assigneeOptions.filter(item => {
    return item.id === this.defaultAssigneeFilter
  })[0]

  selectedTaskStatuses: TaskStatus[] = this.taskStatuses.filter(item => {
    return this.defaultTaskStatuses.includes(item.name)
  })

  get anyTasksHaveChildren () {
    return this.tasks.filter(task => {
      return task.childrenCount > 0
    }).length > 0
  }

  onFiltersChanged (params: any) {
    this.selectedTaskStatuses = params.taskStatuses
    this.selectedAssignee = params.assignee
    this.getTasks()
  }

  @Watch('company')
  onCompanyChanged () {
    this.loading = true
    Vue.nextTick(() => {
      this.expandAll()
    })
  }

  @Watch('project')
  onProjectChanged () {
    this.newTask.project = this.project
    this.getTasks()
  }

  @Watch('projects')
  onProjectsChanged () {
    this.getTasks()
  }

  @Watch('dateView')
  onDateViewChanged () {
    this.getTasks()
  }

  async mounted () {
    window.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        this.hideNewTask()
        this.hideNewSubtasks()
      }
    })
    await this.getTasks()
    this.expandAll()
  }

  async getTasks () {
    this.hideNewTask()

    this.rawTasks = (await Task
      .where({
        archived: false,
        companyId: this.company ? this.company.id : null,
        projectId: this.project ? this.project.id : null,
        taskStatusId: this.selectedTaskStatuses.map(item => { return item.id }),
        assigneeId: this.selectedAssignee ? this.userMemberIdLookup[this.selectedAssignee.id!] : null
      })
      .includes('taskStatus')
      .includes('taskAssignments')
      .includes('project')
      .includes('company')
      .order({
        ancestry: 'desc',
        position: 'asc'
      })
      .per(10000)
      .all()
    ).data

    this.tasks = this.prepareNestedTasks(this.rawTasks)
    this.groupedTasks = this.prepareGroupedTasks(this.tasks)

    this.loading = false
  }

  prepareNestedTasks (tasks: Task[]) {
    const nestedTasks: Task[] = []
    tasks.forEach(task => {
      if (!task.ancestry) {
        nestedTasks.push(task)
      } else {
        const parentTask = nestedTasks.filter(item => {
          return item.id === task.ancestry
        })[0] as any
        if (!parentTask) return
        if (!parentTask.children) parentTask.children = []
        parentTask.children.push(task)
        parentTask.children.sort((a: Task, b: Task) => {
          return a.position > b.position ? 1 : 0
        })
      }
    })
    return nestedTasks
  }

  prepareGroupedTasks (tasks: Task[]) {
    const groups = JSON.parse(JSON.stringify(this.getTaskGroups()))
    if (!this.dateView || this.dateView === 'All Tasks') {
      groups[0].tasks = tasks
      return groups
    } else {
      tasks.forEach(task => {
        this.assignTaskToGroup(task, groups)
      })
    }
    return groups
  }

  getTaskGroups () {
    switch (this.dateView) {
      case 'Daily':
        return this.taskGroups.daily
      case 'Weekly':
        return this.taskGroups.weekly
      case 'Monthly':
        return this.taskGroups.monthly
      default:
        return this.taskGroups.default
    }
  }

  getGroupForTask (task: Task, groups: TaskGroup[]) {
    const taskDueDate = task.dueDate ? dayjs(task.dueDate) : null
    const pastGroup = groups[0]
    const futureGroup = groups[groups.length - 1]

    const isPast = taskDueDate && taskDueDate.isSameOrBefore(pastGroup.endDate!, 'date')

    let taskGroup = futureGroup
    groups.forEach(group => {
      if (isPast && group.title === 'Past') {
        taskGroup = group
      } else {
        const gteStartDate = taskDueDate && group.startDate && taskDueDate.isSameOrAfter(group.startDate!, 'date')
        const lteEndDate = taskDueDate && group.endDate && taskDueDate.isSameOrBefore(group.endDate!, 'date')
        const isWithinGroup = gteStartDate && lteEndDate
        if (isWithinGroup) taskGroup = group
      }
    })

    return taskGroup
  }

  assignTaskToGroup (task: Task, groups: TaskGroup[]) {
    const taskGroup = this.getGroupForTask(task, groups)
    taskGroup.tasks.push(task)
  }

  showTaskGroup (taskGroup: TaskGroup) {
    if (taskGroup.title === 'Past' && taskGroup.tasks.length === 0) return false
    return true
  }

  showTitle (taskGroup: TaskGroup) {
    return taskGroup.title !== 'All Tasks'
  }

  isTopGroup (idx: number) {
    const showingPastGroup = this.groupedTasks?.[0].tasks.length > 0
    return (showingPastGroup && idx === 0) ||
      (!showingPastGroup && idx === 1)
  }

  async addSubtask (task: Task) {
    this.newSubtaskIsVisible = false

    await this.getTasks()

    this.expandTask(task)
    this.hideNewTask()

    let subtaskAncestry = String(task.id)
    if (task.ancestry) {
      subtaskAncestry = [task.ancestry, subtaskAncestry].join(this.ancestrySeparator)
    }
    const ancestors = subtaskAncestry.split(this.ancestrySeparator)
    this.newSubtask = new Task({
      project: task.project,
      company: task.company,
      taskStatus: this.taskStatuses[0],
      ancestry: subtaskAncestry,
      ancestryDepth: ancestors.length
    })

    const parentTask = this.tasks.filter(item => {
      return task.id === item.id
    })[0] as any
    if (!parentTask.children) parentTask.children = []

    parentTask.children.unshift(this.newSubtask)

    Vue.nextTick(() => {
      this.newSubtaskIsVisible = true
      this.focusNewTaskDescriptionInput()
      this.selectTask(this.newSubtask)
    })
  }

  async onSaveNewTask () {
    this.getTasks()
    this.showNewTask()
  }

  async onSaveTask (result: any) {
    const task = result.task
    const wasNew = result.wasNew

    await this.getTasks()

    const parentTask = this.tasks.filter(item => {
      return item.id === task.ancestry
    })[0]
    if (wasNew) this.addSubtask(parentTask)
  }

  beforeDragStart (task: Task) {
    this.wasExpandedBeforeDragging = this.isExpanded(task)
    this.collapseTask(task)
  }

  async onDrop (task: Task) {
    await this.getTasks()
    if (this.wasExpandedBeforeDragging) {
      this.expandTask(task)
    }
  }

  isExpanded (task: Task) {
    if (!task.id) return false
    return !this.collapsedTasks[task.id]
  }

  parentIsExpanded (task: Task) {
    if (!task.parentId) return true
    return !this.collapsedTasks[task.parentId]
  }

  onToggleCollapse (task: Task) {
    if (!task.id) return false
    if (!this.collapsedTasks[task.id]) {
      this.collapseTask(task)
    } else {
      this.expandTask(task)
    }
  }

  expandAll () {
    this.tasks.forEach(task => {
      this.expandTask(task)
    })
    this.showCollapseAll = true
  }

  collapseAll () {
    this.hideNewSubtasks()
    this.tasks.forEach(task => {
      this.collapseTask(task)
    })
    this.showCollapseAll = false
  }

  collapseTask (task: Task) {
    this.$set(this.collapsedTasks, task.id as string, true)
  }

  expandTask (task: Task) {
    this.$set(this.collapsedTasks, task.id as string, false)
  }

  selectTask (task: Task) {
    this.selectedTask = task
    if (task.id) {
      this.hideNewTask()
    }
  }

  showNewTask () {
    this.hideNewTask()
    this.newTask = new Task({
      project: this.project,
      company: this.company,
      taskStatus: this.taskStatuses[0]
    })
    Vue.nextTick(() => {
      this.newTaskIsVisible = true
      this.focusNewTaskDescriptionInput()
      this.selectTask(this.newTask)
    })
  }

  hideNewTask () {
    this.newTaskIsVisible = false
    this.hideNewSubtasks()
  }

  hideNewSubtasks () {
    this.newSubtaskIsVisible = false
    this.removeNewSubtaskFromTaskList()
  }

  removeNewSubtaskFromTaskList () {
    // this function allows the top/bottom shadow to
    //  update properly when the new subtask is hidden

    const parentTask = this.tasks.filter(item => {
      return this.newSubtask?.ancestry === item.id
    })[0] as any
    if (!parentTask || !parentTask.children) return

    parentTask.children = this.getPersistedTasksFrom(parentTask.children)

    if (!this.collapsedTasks[parentTask.id]) {
      this.collapseTask(parentTask)
      this.expandTask(parentTask)
    } else {
      this.expandTask(parentTask)
      this.collapseTask(parentTask)
    }
  }

  focusNewTaskDescriptionInput () {
    Vue.nextTick(() => {
      setTimeout(() => {
        const el = document.getElementById('new_task_description_input')
        if (!el) return
        el.focus()
      })
    })
  }

  getPersistedTasksFrom (tasks: Task[]) {
    return tasks.filter(task => {
      return !!task.id
    })
  }
}
