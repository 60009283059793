




















import { Component, Prop, Emit, Vue } from 'vue-property-decorator'
import { Getter, Action, namespace } from 'vuex-class'
import { TinyColor } from '@ctrl/tinycolor'

import Color from '@/models/color'

const App = namespace('app')

@Component
export default class AppColorTag extends Vue {
  @Prop() value!: string
  @Prop() icon!: string
  @Prop() showRemove!: string
  @Prop() colorCode!: string
  @Prop({ default: () => { return true } }) useLightTags!: boolean

  @App.Getter('colors') colors!: Color[]
  @App.Action('colors') loadColors!: Function

  async mounted () {
    if (!this.colors || this.colors.length === 0) {
      this.loadColors()
    }
  }

  get resolvedColor () {
    const code = this.colorCode || 'B3B3B3'
    const matchingColors = this.colors.filter(color => {
      return color.code === code
    })
    return matchingColors.length > 0 ? matchingColors[0] : new Color({ code: code })
  }

  get style () {
    const color = new TinyColor(this.resolvedColor.code)

    let bgColor = color.toString()
    let textColor = 'white'
    if (this.useLightTags) {
      const darkenAmount = Number(this.resolvedColor?.meta?.darkenBy)
      const lightenAmount = Number(this.resolvedColor?.meta?.lightenBy)
      bgColor = lightenAmount && this.value ? color.lighten(lightenAmount).toString() : color.toString()
      textColor = darkenAmount ? color.darken(darkenAmount).toString() : color.toString()
    }

    let styleString = ''
    styleString += 'background-color: ' + bgColor + ';'
    styleString += 'color: ' + textColor + ';'

    return styleString
  }

  @Emit()
  click () { return true }

  @Emit()
  remove () { return true }
}
