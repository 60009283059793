

















































































































































import { Component, Prop, Vue } from 'vue-property-decorator'

import dayjs from 'dayjs'

import Task from '@/models/task'
import User from '@/models/user'
import Note from '@/models/note'

import AppColorBadge from '@/components/AppColorBadge'
import AppColorTag from '@/components/AppColorTag'
import AppNotesTable from '@/components/AppNotesTable'
import Fieldset from 'primevue/fieldset'
import Button from 'primevue/button'

Vue.component('AppNotesTable', AppNotesTable)
Vue.component('AppColorBadge', AppColorBadge)
Vue.component('AppColorTag', AppColorTag)
Vue.component('Fieldset', Fieldset)
Vue.component('Button', Button)

@Component
export default class TaskDetailPanel extends Vue {
  @Prop() task!: Task
  @Prop() memberUserLookup!: { [key: string]: User }

  parentTask: Task | null = null
  notes: Note[] = []
  emptyValue = '-'

  get isSubtask () {
    return !!this.task.ancestry
  }

  get isOverdue () {
    const finalStatuses = ['Done', 'Canceled']
    return !finalStatuses.includes(this.task.taskStatus.name) &&
      this.isBeforeToday(this.task.dueDate as Date)
  }

  mounted () {
    this.getNotes()
    if (this.isSubtask) {
      this.getParentTask()
    }
  }

  async getNotes () {
    if (!this.task.id) return
    this.notes = (await Note
      .where({
        notableType: 'Task',
        notableId: this.task.id
      })
      .includes('updatedBy')
      .order({ createdAt: 'desc' })
      .order('id')
      .per(99999)
      .all()).data
  }

  async getParentTask () {
    const parentTaskId = this.task.ancestry
    if (!parentTaskId) return
    this.parentTask = (await Task.find(parentTaskId)).data
  }

  dayOfWeek (date: Date) {
    return dayjs(date).format('dddd')
  }

  isBeforeToday (date: Date) {
    return dayjs(date).diff(dayjs(), 'days') < 0
  }
}
