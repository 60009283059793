



































































































import { Component, Prop, Emit, Vue } from 'vue-property-decorator'

import User from '@/models/user'
import TaskStatus from '@/models/task_status'

import AppColorTag from '@/components/AppColorTag'
import OverlayPanel from 'primevue/overlaypanel'
import MultiSelect from 'primevue/multiselect'

Vue.component('AppColorTag', AppColorTag)
Vue.component('MultiSelect', MultiSelect)
Vue.component('OverlayPanel', OverlayPanel)

@Component
export default class TasksTableHeader extends Vue {
  @Prop() assigneeOptions!: User[]
  @Prop() taskStatusOptions!: TaskStatus[]
  @Prop() selectedAssignee!: User
  @Prop() providedSelectedTaskStatuses!: TaskStatus[]

  selectedTaskStatuses: TaskStatus[] = []

  get statusFilterActive () {
    return this.selectedTaskStatuses.length > 0 &&
      this.selectedTaskStatuses.length < this.taskStatusOptions.length
  }

  get assigneeFilterActive () {
    return !!this.selectedAssignee
  }

  mounted () {
    this.selectedTaskStatuses = this.providedSelectedTaskStatuses
  }

  toggleAssigneeFilter (event: any = {}) {
    (this.$refs.assignee_filter_overlay as any).show(event)

    if (this.assigneeFilterActive) return
    setTimeout(() => {
      (this.$refs.assignee_filter as any).show(event)
    })
  }

  toggleTaskStatusFilter (event: any = {}) {
    (this.$refs.task_status_filter_overlay as any).show(event)

    if (this.statusFilterActive) return
    setTimeout(() => {
      (this.$refs.status_filter as any).show(event)
    })
  }

  @Emit()
  onFiltersChanged () {
    return {
      taskStatuses: this.selectedTaskStatuses,
      assignee: this.selectedAssignee
    }
  }
}
