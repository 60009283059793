
























































import { Component, Prop, Emit, Vue } from 'vue-property-decorator'

import { dropTask } from '@/pages/Projects/services/drop_task'

import { TaskGroup, DropResult } from '@/types/tasks'

import User from '@/models/user'
import Task from '@/models/task'
import TaskStatus from '@/models/task_status'
import Project from '@/models/project'
import Company from '@/models/company'

import AppDraggableTree from '@/components/AppDraggableTree'
import TaskRow from './TaskRow.vue'
import TaskDetailPanel from './TaskDetailPanel.vue'
import Dialog from 'primevue/dialog'

Vue.component('AppDraggableTree', AppDraggableTree)
Vue.component('TaskRow', TaskRow)
Vue.component('TaskDetailPanel', TaskDetailPanel)
Vue.component('Dialog', Dialog)

@Component
export default class TasksTable extends Vue {
  @Prop() tasks!: Task[]
  @Prop() company!: Company
  @Prop() project!: Project
  @Prop() projects!: Project[]
  @Prop() assigneeOptions!: User[]
  @Prop() memberUserLookup!: { [key: string]: User }
  @Prop() userMemberIdLookup!: { [key: string]: string }
  @Prop() taskStatuses!: TaskStatus[]
  @Prop() newSubtaskIsVisible!: boolean
  @Prop() collapsedTasks!: any
  @Prop() draggedTasks!: any
  @Prop() selectedTask!: Task
  @Prop() taskGroup!: TaskGroup
  @Prop() filteredAssignee!: User

  detailTask: Task | null = null
  showDetailDialog = false

  maxDescriptionCharacters = 0
  settingWindowWidth: any

  mounted () {
    this.initWindowResizeListener()
  }

  initWindowResizeListener () {
    window.addEventListener('resize', this.setWindowWidth)
    this.setWindowWidth()
  }

  beforeDestroy () {
    window.removeEventListener('resize', this.setWindowWidth)
  }

  setWindowWidth () {
    if (this.settingWindowWidth) window.cancelAnimationFrame(this.settingWindowWidth)
    this.settingWindowWidth = window.requestAnimationFrame(() => {
      this.setMaxDescriptionCharacters()
    })
  }

  setMaxDescriptionCharacters () {
    const contentWidth = window.innerWidth < 1200 ? 1200 : window.innerWidth
    const otherElementsWidth = 800
    const taskSectionWidth = contentWidth - otherElementsWidth

    let divisor = 6
    if (contentWidth < 1800) divisor = 7
    if (contentWidth < 1600) divisor = 8
    if (contentWidth < 1500) divisor = 9
    if (contentWidth < 1300) divisor = 10

    this.maxDescriptionCharacters = taskSectionWidth / divisor
  }

  isVisible (task: Task) {
    if (!task.id) return this.newSubtaskIsVisible
    return true
  }

  parentIsExpanded (task: Task) {
    if (!task.parentId) return true
    return !this.collapsedTasks[task.parentId]
  }

  taskDropped (dropResult: DropResult) {
    if (dropResult.addedIndex === null) return
    this.onDrop(dropResult)
  }

  async moveToDueDate (task: Task, dueDate: Date | null) {
    task.dueDate = dueDate
    await task.save()
    return task
  }

  async moveToPosition (task: Task, position: number) {
    task.position = position
    await task.save()
    return task
  }

  async moveToDueDateWithPosition (task: Task, dueDate: Date, position: number) {
    task.dueDate = dueDate
    task.position = position
    task.shouldPlacePrecisely = true
    await task.save()
    return task
  }

  openDetailDialog (task: Task) {
    this.detailTask = task || this.selectedTask
    this.showDetailDialog = true
  }

  @Emit()
  addSubtask (task: Task) {
    return task
  }

  @Emit()
  onSaveTask (result: any) {
    return result
  }

  @Emit()
  beforeDragStart (task: Task) {
    return task
  }

  @Emit()
  async onDrop (dropResult: DropResult) {
    const { droppedTask, newPosition, newDueDate } = dropTask(dropResult, this.taskGroup, this.tasks)

    if (newPosition !== null && newDueDate !== null) {
      return await this.moveToDueDateWithPosition(droppedTask, newDueDate, newPosition)
    } else if (newPosition !== null) {
      return await this.moveToPosition(droppedTask, newPosition)
    } else if (newDueDate !== null) {
      return await this.moveToDueDate(droppedTask, newDueDate)
    } else {
      return await this.moveToDueDate(droppedTask, null)
    }
  }

  @Emit()
  onToggleCollapse (task: Task) {
    return task
  }

  @Emit()
  hideNewSubtasks () {
    return null
  }

  @Emit()
  selectTask (task: Task) {
    return task
  }

  @Emit()
  onHideTaskDetailDialog () {
    return null
  }
}
