


































import { Component, Watch, Vue } from 'vue-property-decorator'
import { Getter, Action, namespace } from 'vuex-class'

import Company from '@/models/company'
import Project from '@/models/project'
import Color from '@/models/color'
import User from '@/models/user'
import Membership from '@/models/membership'
import TaskStatus from '@/models/task_status'
import MemberAccessible from '@/models/member_accessible'

import ProjectsSidebar from './components/ProjectsSidebar'
import ProjectsToolbar from './components/ProjectsToolbar.vue'
import TaskContent from './components/TaskContent'

Vue.component('ProjectsSidebar', ProjectsSidebar)
Vue.component('ProjectsToolbar', ProjectsToolbar)
Vue.component('TaskContent', TaskContent)

const App = namespace('app')
const Auth = namespace('auth')

@Component
export default class Projects extends Vue {
  @Auth.Getter currentMemberId!: string
  @App.Action('colors') loadColors!: Function

  loading = true
  loadingProjects = true

  companies: Company[] = []
  projects: Project[] = []
  members: Membership[] = []
  taskStatuses: TaskStatus[] = []
  memberAccessibles: MemberAccessible[] = []

  selectedCompany: Company | null = null
  selectedProject: Project | null = null
  selectedDateView: string | null = null

  currentMember: Membership | null = null
  memberUserLookup: { [key: string]: User } = {}
  userMemberIdLookup: { [key: string]: string } = {}

  get defaultDateView () {
    if (this.currentMember && this.currentMember.memberPreference) {
      return this.currentMember.memberPreference.defaultProjectDateFilter
    }
  }

  get defaultAssigneeFilter () {
    if (this.currentMember && this.currentMember.memberPreference) {
      return this.currentMember.memberPreference.defaultProjectAssigneeFilter
    }
  }

  get membersAsUsers () {
    const memberIdsForCompany = this.memberAccessibles.map(item => {
      return item.memberId
    })
    return this.members.filter(member => {
      return memberIdsForCompany.includes(member.id!)
    }).map(member => {
      return member.user
    }).filter(user => {
      return user.firstName !== 'System'
    }).sort((a: User, b: User) => {
      return a.fullName.localeCompare(b.fullName)
    })
  }

  @Watch('selectedCompany')
  onSelectedCompanyChanged () {
    this.selectedProject = null
    this.getMemberAccessibles()
    this.getProjects()
  }

  async mounted () {
    await Promise.all([
      this.getProjects(),
      this.getColors(),
      this.getTaskStatuses(),
      this.getCurrentMember()
    ])
    this.loading = false
    await this.getMembersForCurrentTenant()
  }

  async getProjects () {
    if (!this.selectedCompany) return []
    this.projects = (await Project
      .where({
        archived: false,
        companyId: this.selectedCompany.id
      })
      .includes('company')
      .order('name')
      .per(10000)
      .all()
    ).data
    this.loadingProjects = false
  }

  async getColors () {
    this.loadColors()
  }

  async getMemberAccessibles () {
    if (!this.selectedCompany) return
    this.memberAccessibles = (await MemberAccessible
      .where({
        accessibleType: 'Company',
        accessibleId: this.selectedCompany.id
      })
      .per(10000)
      .all()
    ).data
  }

  async getTaskStatuses () {
    this.taskStatuses = (await TaskStatus
      .per(10000)
      .all()
    ).data
  }

  async getCurrentMember () {
    this.currentMember = (await Membership
      .includes('user')
      .includes('memberPreference')
      .find(this.currentMemberId)
    ).data
  }

  async getMembersForCurrentTenant () {
    this.members = (await Membership.where({
      tenantId: this.currentMember!.user.currentTenantId
    }).includes('user').per(10000).all()).data

    const memberUserLookup: { [key: string]: User } = {}
    this.members.forEach(member => {
      memberUserLookup[member.id as string] = member.user
    })
    this.memberUserLookup = memberUserLookup

    const userMemberIdLookup: { [key: string]: string } = {}
    this.members.forEach(member => {
      userMemberIdLookup[member.user.id as string] = member.id!
    })
    this.userMemberIdLookup = userMemberIdLookup
  }
}
