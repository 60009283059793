var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('AppDialog',{attrs:{"header":"You're deleting a project.","content":"Do you also want to remove the associated tasks?","show":_vm.showProjectDeleteDialog,"buttons":[
      {
        label: 'Delete Project (Keep Tasks)',
        action: _vm.archiveProject,
        disabled: _vm.archiving,
        class: 'p-button-secondary'
      },
      {
        label: 'Delete Project (Remove Tasks)',
        disabled: _vm.archiving,
        action: _vm.archiveProjectWithAssociatedTasks,
        class: 'p-button-secondary'
      }, {
        label: 'Cancel',
        disabled: _vm.archiving,
        action: function () { _vm.showProjectDeleteDialog = false; _vm.cancel() },
        class: 'p-button-outlined p-button-danger'
      }
    ]},on:{"hide":function($event){_vm.showProjectDeleteDialog = false}}}),_c('form',{staticClass:"p-col p-text-left",staticStyle:{"max-width":"400px"},on:{"submit":function($event){$event.preventDefault();return _vm.save($event)}}},[_c('div',{staticClass:"p-fluid"},[_c('div',{staticClass:"p-field"},[_c('label',{attrs:{"for":"name"}},[_vm._v("Name*")]),_c('InputText',{attrs:{"id":"name","autocomplete":"off","disabled":_vm.saving},model:{value:(_vm.project.name),callback:function ($$v) {_vm.$set(_vm.project, "name", $$v)},expression:"project.name"}})],1)]),_c('div',{staticClass:"p-fluid"},[_c('div',{staticClass:"p-field"},[_c('label',{attrs:{"for":"color"}},[_vm._v("Color")]),_c('AppColorPicker',{attrs:{"value":_vm.project.colorCode,"colorOptions":_vm.colorOptions},on:{"set-selected-color":_vm.setSelectedColor}})],1)]),_c('div',{staticClass:"p-mb-3 p-mt-5"},[_c('Button',{staticClass:"p-mr-2 width-100",attrs:{"type":"submit","label":"Save","disabled":_vm.saving || _vm.invalid}})],1),(!_vm.isNew)?_c('div',{staticClass:"p-mt-3 p-mb-0"},[_c('Button',{staticClass:"p-button-danger p-button-outlined width-100",attrs:{"label":"Delete","disabled":_vm.saving},on:{"click":_vm.promptForDelete}})],1):_vm._e(),_c('div',{staticClass:"p-mt-3 p-mb-0"},[_c('Button',{staticClass:"p-button-secondary p-button-outlined width-100",attrs:{"label":"Cancel","disabled":_vm.saving},on:{"click":_vm.cancel}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }