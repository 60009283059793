import Task from '@/models/task'

import { DroppedTaskResult, TaskGroup, DropResult } from '@/types/tasks'

export const dropTask = (dropResult: DropResult, taskGroup: TaskGroup, tasks: Task[]) => {
  const { removedIndex, addedIndex, payload } = dropResult
  const droppedTask = payload.item

  const movingWithinSameGroup = removedIndex !== null && addedIndex !== null

  const movingToEmptyGroup = tasks.length === 0
  const movingToTopOfNewFilledGroup = !movingWithinSameGroup && !movingToEmptyGroup && addedIndex === 0
  const movingToBottomOfNewFilledGroup = !movingWithinSameGroup && !movingToEmptyGroup && addedIndex === tasks.length
  const movingToMiddleOfNewFilledGroup = !movingWithinSameGroup && !movingToEmptyGroup && addedIndex !== 0 && addedIndex !== tasks.length

  const result: DroppedTaskResult = {
    droppedTask: droppedTask,
    newPosition: null,
    newDueDate: null
  }

  if (movingToEmptyGroup) {
    result.newDueDate = taskGroup.endDate
  } else if (movingToTopOfNewFilledGroup) {
    result.newDueDate = taskGroup.startDate
    const taskBeforeDroppedTask = tasks[0]
    result.newPosition = taskBeforeDroppedTask.position

    const movingLower = result.newPosition > droppedTask.position
    if (movingLower) result.newPosition -= 1
  } else if (movingToBottomOfNewFilledGroup) {
    result.newDueDate = taskGroup.endDate
    const taskBeforeDroppedTask = tasks[tasks.length - 1]
    result.newPosition = taskBeforeDroppedTask.position

    const movingHigher = result.newPosition < droppedTask.position
    if (movingHigher) result.newPosition += 1
  } else if (movingToMiddleOfNewFilledGroup) {
    const taskBeforeDroppedTask = tasks[addedIndex!]
    result.newPosition = taskBeforeDroppedTask.position

    const movingLower = result.newPosition > droppedTask.position
    if (movingLower) result.newPosition -= 1
  } else {
    if (droppedTask.ancestry) {
      const siblingTasks = tasks = tasks.filter(task => {
        return task.id === droppedTask.ancestry
      })
      if (siblingTasks && siblingTasks.length > 0 && siblingTasks[0].children) {
        tasks = siblingTasks[0].children
      }
    }
    const taskBeforeDroppedTask = tasks[addedIndex!]
    result.newPosition = taskBeforeDroppedTask.position
  }

  return result
}
