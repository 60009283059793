import ApplicationRecord from '@/models/application_record'
import { User } from '@/models'

import {
  Model,
  Attr,
  BelongsTo
} from 'spraypaint'

@Model()
export default class Note extends ApplicationRecord {
  static jsonapiType = 'notes'

  @Attr notableType!: string | null
  @Attr notableId!: string | null
  @Attr body!: string
  @Attr createdById!: string
  @Attr updatedById!: string
  @Attr({ persist: false }) createdAt!: Date
  @Attr({ persist: false }) updatedAt!: Date

  @BelongsTo() createdBy!: User
  @BelongsTo() updatedBy!: User
}
