import ApplicationRecord from '@/models/application_record'
import { Company } from '@/models'

import {
  Model,
  Attr,
  BelongsTo
} from 'spraypaint'

@Model()
export default class Project extends ApplicationRecord {
  static jsonapiType = 'projects'

  @Attr name!: string
  @Attr colorCode!: string
  @Attr archived!: boolean
  @Attr withAssociatedTasks!: boolean | undefined

  @BelongsTo() company!: Company
}
