import ApplicationRecord from '@/models/application_record'

import {
  Model,
  Attr
} from 'spraypaint'

@Model()
export default class TaskStatus extends ApplicationRecord {
  static jsonapiType = 'task_statuses'

  @Attr name!: string
}
