import ApplicationRecord from '@/models/application_record'

import {
  TaskAssignment,
  TaskStatus,
  TaskList,
  Project,
  Company,
  Note
} from '@/models'

import {
  BelongsTo,
  HasMany,
  Model,
  Attr
} from 'spraypaint'

@Model()
export default class Task extends ApplicationRecord {
  static jsonapiType = 'tasks'

  @Attr dueDate!: Date | string | null
  @Attr description!: string
  @Attr lastNoteDate!: Date
  @Attr projectId!: string | null
  @Attr createdAt!: Date
  @Attr ancestry!: string | null
  @Attr ancestryDepth!: number
  @Attr childrenCount!: number
  @Attr position!: number
  @Attr taskStatusId!: string
  @Attr companyId!: string
  @Attr tagDate!: Date | string | null
  @Attr noteCount!: number
  @Attr archived!: boolean
  @Attr shouldPlacePrecisely!: boolean | undefined

  @Attr({ persist: false }) children!: Task[] | undefined

  @BelongsTo() project!: Project | null
  @BelongsTo() company!: Company
  @BelongsTo() taskStatus!: TaskStatus
  @BelongsTo() taskList!: TaskList

  @HasMany() notes!: Note[]
  @HasMany() taskAssignments!: TaskAssignment[]
  @HasMany() subtasks!: Task[]

  get parentId (): string | null {
    return this.ancestorIds[this.ancestorIds.length - 1]
  }

  get ancestorIds (): string[] {
    if (!this.ancestry) return []

    const separator = '/'
    return this.ancestry.split(separator)
  }
}
