

















































































































































import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { Getter, namespace } from 'vuex-class'

import Note from '@/models/note'
import User from '@/models/user'

import AppDialog from '@/components/AppDialog'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'

Vue.component('AppDialog', AppDialog)
Vue.component('DataTable', DataTable)
Vue.component('Button', Button)
Vue.component('Column', Column)
Vue.component('InputText', InputText)

const Auth = namespace('auth')

@Component
export default class AppNotesTable extends Vue {
  @Prop() notes!: Note[]
  @Prop() notableType!: string
  @Prop() notableId!: string

  @Auth.Getter currentUserId!: string

  loggedInUser: User | null = null

  editingRows: Note[] = []

  newNote = new Note()
  selectedNote: Note | null = null
  notePendingDelete: Note | null = null

  showNoteDeleteDialog = false

  async mounted () {
    await this.getCurrentUser()
    await this.resetNewNote()
    await this.resetEditingRows()
  }

  async getCurrentUser () {
    this.loggedInUser = (await User.find(this.currentUserId)).data
  }

  resetEditingRows () {
    this.editingRows = [this.newNote]
  }

  resetNewNote () {
    this.newNote = new Note({
      notableType: this.notableType,
      notableId: this.notableId,
      createdBy: this.loggedInUser,
      updatedBy: this.loggedInUser
    })
    this.focusNewNote()
  }

  focusNewNote () {
    const refs = this.$refs
    setTimeout(() => {
      if (refs?.new_note_body) {
        (refs.new_note_body as any).$el.focus()
      }
    }, 200)
  }

  noteIsValid (note: Note) {
    if (!note.body) return false
    return true
  }

  @Emit()
  async saveNote (note: Note) {
    await note.save({
      with: [
        'createdBy.id',
        'updatedBy.id'
      ]
    })
    this.afterSaveNote()
  }

  afterSaveNote () {
    this.resetNewNote()
    this.resetEditingRows()
  }

  onRowEditInit (note: Note) {
    this.editingRows = [this.newNote, note]
    this.selectedNote = note
  }

  onRowEditCancel () {
    this.editingRows = [this.newNote]
  }

  updateNote (note: Note) {
    if (!this.loggedInUser) return
    note.updatedBy = this.loggedInUser
    this.saveNote(note)
  }

  promptForDeleteNote (note: Note) {
    this.notePendingDelete = note
    this.resetEditingRows()
    this.showNoteDeleteDialog = true
  }

  @Emit()
  async deleteNote (note: Note | null = this.notePendingDelete) {
    if (!note) return
    await note.destroy()
    this.showNoteDeleteDialog = false
  }
}
