













































































import { Component, Prop, Emit, Vue } from 'vue-property-decorator'

import Project from '@/models/project'
import Color from '@/models/color'

import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import Dropdown from 'primevue/dropdown'
import AppColorPicker from '@/components/AppColorPicker'
import AppDialog from '@/components/AppDialog'

Vue.component('Button', Button)
Vue.component('InputText', InputText)
Vue.component('Dropdown', Dropdown)
Vue.component('AppColorPicker', AppColorPicker)
Vue.component('AppDialog', AppDialog)

@Component
export default class ProjectForm extends Vue {
  @Prop() project!: Project
  @Prop() colorOptions!: Color[]

  saving = false
  archiving = false
  showProjectDeleteDialog = false

  wasNew = false

  get isNew () {
    return !this.project.id
  }

  get invalid () {
    return !this.project.name
  }

  setSelectedColor (value: string) {
    this.project.colorCode = value
  }

  async save () {
    this.saving = true
    this.wasNew = this.isNew
    await this.project.save({ with: ['company.id'] })
    this.saveComplete()
  }

  async archiveProject () {
    this.archiving = true
    this.project.archived = true
    await this.project.save()
    this.deleteComplete()
  }

  async archiveProjectWithAssociatedTasks () {
    this.archiving = true
    this.project.archived = true
    this.project.withAssociatedTasks = true
    await this.project.save()
    this.deleteComplete()
  }

  promptForDelete () {
    this.showProjectDeleteDialog = true
  }

  @Emit()
  saveComplete (project: Project = this.project) {
    this.saving = false
    return project
  }

  @Emit()
  deleteComplete (project: Project = this.project) {
    this.archiving = false
    return project
  }

  @Emit()
  cancel (project: Project = this.project) {
    return project
  }
}
