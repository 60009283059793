


















































































import { Component, Prop, Watch, Emit, Vue } from 'vue-property-decorator'
import { Getter, namespace } from 'vuex-class'

import Company from '@/models/company'
import Project from '@/models/project'
import Color from '@/models/color'

import ProjectForm from './ProjectForm.vue'
import AppColorTag from '@/components/AppColorTag'
import AppOverlayPanel from '@/components/AppOverlayPanel'
import OverlayPanel from 'primevue/overlaypanel'
import Listbox from 'primevue/listbox'
import Dropdown from 'primevue/dropdown'
import Button from 'primevue/button'

Vue.component('ProjectForm', ProjectForm)
Vue.component('AppColorTag', AppColorTag)
Vue.component('AppOverlayPanel', AppOverlayPanel)
Vue.component('OverlayPanel', OverlayPanel)
Vue.component('Listbox', Listbox)
Vue.component('Dropdown', Dropdown)
Vue.component('Button', Button)

const Auth = namespace('auth')
const App = namespace('app')

@Component
export default class ProjectsSidebar extends Vue {
  @Prop() selectedCompany!: Company | null
  @Prop() projects!: Project[]
  @Prop() defaultDateView!: string

  @Auth.Getter currentPermissions!: string
  @App.Getter colors!: Color[]

  selectedProject: Project | null = null
  projectToEdit: Project | null = null
  lastProjectEdited: Project | null = null
  newProject: Project | null = null

  enableCreateProject = false
  enableUpdateProject = false

  selectedDateView = this.defaultDateView || 'All Tasks'
  dateViewOptions = [
    'All Tasks',
    'Daily',
    'Weekly',
    'Monthly'
  ]

  get projectColors () {
    return this.colors.filter(color => {
      return color.name !== 'Gray'
    })
  }

  get defaultProjectColor () {
    return this.colors.filter(color => {
      return color.name === 'Blue'
    })[0]?.code
  }

  @Watch('selectedCompany')
  onSelectedCompanyChanged () {
    this.selectedProject = null
  }

  @Emit()
  @Watch('selectedProject')
  selectedProjectChanged (newValue: Project, oldValue: Project) {
    const oldValueSameAsEditable = this.selectedProject === null &&
      this.projectToEdit !== null &&
      this.projectToEdit === oldValue &&
      newValue === null

    const newValueDifferentFromEditable = this.projectToEdit !== null &&
      newValue !== null &&
      newValue.id !== this.projectToEdit.id

    if (oldValueSameAsEditable) {
      // purpose: when the pencil button is cliecked, the listbox
      //  sets selectedProject to null, but really it should stay
      //  selected
      this.selectedProject = oldValue
    }
    if (newValueDifferentFromEditable) {
      // purpose: when selecting a row that is not currently being
      //  edited, the project form overlay should be closed
      this.projectToEdit = null
      this.hideProjectFormOverlays()
    }

    return this.selectedProject
  }

  mounted () {
    this.getCurrentPermissions()
  }

  getCurrentPermissions () {
    const permissions = this.currentPermissions
    if (!permissions) return

    this.enableCreateProject = permissions.includes('create_project')
    this.enableUpdateProject = permissions.includes('update_project')
  }

  onAddNewProject (event: any) {
    this.newProject = new Project({
      company: this.selectedCompany!,
      colorCode: this.defaultProjectColor
    })
    this.showNewProjectFormOverlay(event)
  }

  onClickEditProject (project: Project) {
    this.selectedProject = project
    this.projectToEdit = project
    this.showProjectFormOverlay()
  }

  @Emit()
  async afterSaveProject (project: Project) {
    this.selectedProject = project
    this.projectToEdit = null
    this.hideProjectFormOverlays()
  }

  @Emit()
  async afterDeleteProject () {
    this.selectedProject = null
    this.projectToEdit = null
    this.hideProjectFormOverlays()
  }

  @Emit()
  @Watch('selectedDateView')
  dateViewChanged () {
    return this.selectedDateView
  }

  async onCancelProject () {
    if (this.selectedProject) {
      this.selectedProject = (await Project.find(this.selectedProject.id!)).data
    }
    this.projectToEdit = null
    this.hideProjectFormOverlays()
  }

  showNewProjectFormOverlay (event: any = {}) {
    this.hideProjectFormOverlays()
    if (this.lastProjectEdited) {
      this.projectToEdit = this.lastProjectEdited
    }
    (this.$refs.project_form_overlay as any).show(event)
  }

  showProjectFormOverlay (event: any = {}) {
    if (!this.selectedProject) return
    this.hideProjectFormOverlays()
    if (this.lastProjectEdited) {
      this.projectToEdit = this.lastProjectEdited
    }
    (this.$refs['project_form_overlay_' + this.selectedProject.id] as any).show(event)
  }

  hideProjectFormOverlays () {
    let el = this.$refs.project_form_overlay as any
    el.hide()

    let ref: string
    this.projects.forEach(project => {
      ref = 'project_form_overlay_' + project.id

      el = this.$refs[ref] as any
      el.hide()
    })
  }
}
