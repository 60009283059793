









































import { Component, Prop, Emit, Vue } from 'vue-property-decorator'

import { Container, Draggable } from 'vue-smooth-dnd'
import { applyDrag } from './services/applyDrag'

Vue.component('Container', Container)
Vue.component('Draggable', Draggable)

@Component
export default class AppDraggableTree extends Vue {
  @Prop() items!: any[]
  @Prop() isPlaceholder!: boolean
  @Prop({ default: () => { return 0 } }) depth!: number

  getChildPayload (index: number) {
    return {
      index: index,
      item: this.items[index]
    }
  }

  @Emit()
  onDragStart (dragResult: any) {
    return dragResult
  }

  @Emit()
  onDragEnd (dragResult: any) {
    return dragResult
  }

  @Emit()
  onDragEnter () {
    return true
  }

  @Emit()
  onDragLeave () {
    return true
  }

  @Emit()
  onDropReady (item: any) {
    return item
  }

  @Emit()
  onDrop (dropResult: any) {
    this.items = applyDrag(this.items, dropResult)
    return dropResult
  }

  @Emit()
  onNestedDrop (dropResult: any) {
    return dropResult
  }
}
