import ApplicationRecord from '@/models/application_record'

import {
  Model,
  Attr
} from 'spraypaint'

@Model()
export default class TaskAssignment extends ApplicationRecord {
  static jsonapiType = 'task_assignments'

  @Attr taskId!: string
  @Attr memberId!: string
}
