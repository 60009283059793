

























import { Component, Emit, Watch, Prop, Vue } from 'vue-property-decorator'

import Company from '@/models/company'
import Membership from '@/models/membership'

import Toolbar from 'primevue/toolbar'
import Dropdown from 'primevue/dropdown'

Vue.component('Toolbar', Toolbar)
Vue.component('Dropdown', Dropdown)

@Component
export default class ProjectsToolbar extends Vue {
  @Prop() currentMember!: Membership

  selectedCompany: Company | null = null
  companies: Company[] = []

  @Emit()
  @Watch('selectedCompany')
  selectedCompanyChanged () {
    return this.selectedCompany
  }

  mounted () {
    this.getCompanies()
  }

  async getCompanies () {
    this.companies = (await Company
      .order('name')
      .per(10000)
      .all()
    ).data
    this.setInitialCompany()
  }

  setInitialCompany () {
    if (this.currentMember.memberPreference) {
      this.selectedCompany = this.companies.filter(company => {
        return company.id === this.currentMember.memberPreference.defaultCompanyId
      })[0]
    } else if (this.companies.length === 1) {
      this.selectedCompany = this.companies[0]
    }
  }
}
