













// @ts-nocheck

// This file is heavily sourced from the PrimeVue OverlayPanel component
// Its purpose is to eliminate some undesirable behavior from the PrimeVue component

import { Component, Prop, Vue } from 'vue-property-decorator'

import Dialog from 'primevue/dialog'
import Button from 'primevue/button'

Vue.component('Dialog', Dialog)
Vue.component('Button', Button)

@Component
export default class AppOverlayPanel extends Vue {
  @Prop({ default: () => { return true } }) dismissable!: boolean
  @Prop({ default: () => { return false } }) showCloseIcon!: boolean
  @Prop({ default: () => { return null } }) appendTo!: string
  @Prop({ default: () => { return 0 } }) baseZIndex!: number
  @Prop({ default: () => { return true } }) autoZIndex!: boolean
  @Prop({ default: () => { return 'close' } }) ariaCloseLabel!: string

  visible = false

  target = null
  outsideClickListener = null
  scrollHandler = null
  resizeListener = null

  beforeDestroy () {
    this.restoreAppend()
    if (this.dismissable) {
      this.unbindOutsideClickListener()
    }
    if (this.scrollHandler) {
      this.scrollHandler.destroy()
      this.scrollHandler = null
    }
    this.unbindResizeListener()
    this.target = null
  }

  toggle (event) {
    if (this.visible) { this.hide() } else { this.show(event) }
  }

  show (event) {
    this.visible = true
    this.target = event.currentTarget
  }

  hide () {
    this.visible = false
  }

  onEnter () {
    this.appendContainer()
    if (this.dismissable) {
      this.bindOutsideClickListener()
    }
    this.bindResizeListener()
  }

  onLeave () {
    this.unbindOutsideClickListener()
    this.unbindResizeListener()
  }

  bindOutsideClickListener () {
    if (!this.outsideClickListener) {
      this.outsideClickListener = (event) => {
        if (this.visible && this.$refs.container && !this.$refs.container.contains(event.target) && !this.isTargetClicked(event)) {
          this.visible = false
        }
      }
      document.addEventListener('click', this.outsideClickListener)
    }
  }

  unbindOutsideClickListener () {
    if (this.outsideClickListener) {
      document.removeEventListener('click', this.outsideClickListener)
      this.outsideClickListener = null
    }
  }

  bindResizeListener () {
    if (!this.resizeListener) {
      this.resizeListener = () => {
        if (this.visible) {
          this.visible = false
        }
      }
      window.addEventListener('resize', this.resizeListener)
    }
  }

  unbindResizeListener () {
    if (this.resizeListener) {
      window.removeEventListener('resize', this.resizeListener)
      this.resizeListener = null
    }
  }

  isTargetClicked () {
    return this.target && (this.target === event.target || this.target.contains(event.target))
  }

  appendContainer () {
    if (this.appendTo) {
      if (this.appendTo === 'body') { document.body.appendChild(this.$refs.container) } else { document.getElementById(this.appendTo).appendChild(this.$refs.container) }
    }
  }

  restoreAppend () {
    if (this.$refs.container && this.appendTo) {
      if (this.appendTo === 'body') { document.body.removeChild(this.$refs.container) } else { document.getElementById(this.appendTo).removeChild(this.$refs.container) }
    }
  }
}
